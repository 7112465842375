import { Component, Input, inject } from '@angular/core';
import { catchError, distinctUntilChanged, map } from 'rxjs';

import { AppData } from '../../../app/data';
import { InspectionResultEnum } from '../../models/inspection';
import { ScheduledEvent } from '../job-list/job-list.component';

@Component({
  selector: 'app-job-or-nonservice-period',
  templateUrl: './job-or-nonservice-period.component.html',
  styleUrls: ['./job-or-nonservice-period.component.scss'],
})
export class JobOrNonservicePeriodComponent {

  @Input() jobOrNonServicePeriod: ScheduledEvent;

  public readonly data = inject(AppData);

  public preTripPassed$ = this.data.route$.pipe(
    map(route => {
      if (!route.preTrip) { return true; } // when the route does not have a pre-trip inspection
      if (!route.preTrip.hasBeenCompleted && route.preTrip.inspectionItems?.length) { return false; }
      return !route.preTrip.inspectionItems.some(item => item.isCritical && item.result === InspectionResultEnum.ServiceCritical);
    }),
    distinctUntilChanged(),
    catchError(async err => true),
  );

}
