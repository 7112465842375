import { Component, Input, OnInit } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { LoadingController, ModalController } from '@ionic/angular';
import { NotificationService } from '../../services/notification.service';
import { DeviceService } from '../../services/device.service';
import { FlagStopPassScanPage } from '../flag-stop-pass-scan/flag-stop-pass-scan.page';
import { LocationService } from '../../services/location.service';
import { FlagStopJob, FlagStopPassScan, ScheduledFlagStop } from '../../models/flag-stop';
import { DispatcherService } from '../../services/dispatcher.service';
import { PassDetails, isCashPass, isSiucPass } from '../../models/pass';
import { AlertService } from '../../services/alert.service';
import { RiderTypePerform, totalBoardedAtThisStop, totalDeboardedAtThisStop, totalOnboard, totalPassFareCollected } from '../../models/rider-type-perform';
import { PassEntryPage } from '../pass-entry/pass-entry.page';
import { TranslateService } from '../../services/translate.service';
import { NativePluginService } from '../../services/plugin/native-plugins.service';
import { AppData } from 'src/app/data';
import { IncrementalControlComponent } from 'src/app/components/increment-control/increment-control.component';

@Component({
  selector: 'app-flag-stop-perform',
  templateUrl: './flag-stop-perform.page.html',
  styleUrls: ['./flag-stop-perform.page.scss'],
})
export class FlagStopPerformPage implements OnInit {

  public riderTypePerforms: RiderTypePerform[] = [];
  public odometer: number;
  public paraPassEnabled: boolean = this.data.paraPassEnabled;
  public rapidPassEnabled: boolean = this.data.rapidPassEnabled;

  @Input() public scheduledFlagStop: ScheduledFlagStop = <ScheduledFlagStop>{};

  private readonly gtm = (<any>window).dataLayer || [];

  constructor(
    private data: AppData,
    private deviceService: DeviceService,
    private alertService: AlertService,
    private dispatcherService: DispatcherService,
    private loadingCtrl: LoadingController,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private modalController: ModalController,
    private numberPipe: DecimalPipe,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private pluginService: NativePluginService,
  ) { }

  private static driverConfirmationRequired(passInfo: PassDetails, recursive: boolean): boolean {
    return !passInfo.isSufficientForRide          // the pass does not sufficiently cover the ride
      || (
        (passInfo.debitAmoutForRide || 0) > 0 // there is a fare that needs to be collected
        && !recursive)  // and we arent rapid scanning
      || (isSiucPass(passInfo.passType)                   // Its a SIUC pass and either
        && (passInfo.integrationFailure           // the db is offline
          || !passInfo.siucStudentIsActive        // the student is not active
          || !passInfo.siucMassTransitEnabled     // not eligible for transit
          || !passInfo.siucDawgTagIsValid));      // the student dog tag is not found
  }

  async ngOnInit(): Promise<void> {
    this.gtm.push({ event: 'custom_event', custom_event_name: this.scheduledFlagStop?.id ? 'view_perform_scheduled_stop' : 'view_perform_flag_stop' });
    // build a list of ride types from the provider list
    this.riderTypePerforms = this.createRideTypePerforms();
    this.odometer = this.data.gpsReport?.odometer;
  }

  public get passengerDescription(): string {
    return (!this.scheduledFlagStop && this.data.route.flagStopsEnabled) ?
      this.translateService.translate('LABEL.flagStopsOnBoard') :
      this.translateService.translate('LABEL.totalPassengersOnBoard');
  }

  public get passengerDescriptionShort(): string {
    return this.translateService.translate('LABEL.passengersOnBoard');
  }

  public get totalPassengersOnBoard(): number {
    return this.riderTypePerforms
      .map(t => this.totalOnboard(t))
      .reduce((sum, current) => sum + current, 0);
  }

  public get totalPassengersBoarded(): number {
    return this.riderTypePerforms
      .map(t => this.totalBoardedAtThisStop(t))
      .reduce((sum, current) => sum + current, 0);
  }

  public get totalPassengersDeboarded(): number {
    return this.riderTypePerforms
      .map(t => this.totalDeboardedAtThisStop(t))
      .reduce((sum, current) => sum + current, 0);
  }

  public get totalPassengersBoardedOrDeboardedAtThisStop(): number {
    return this.riderTypePerforms
      .map(t => totalBoardedAtThisStop(t) - totalDeboardedAtThisStop(t))
      .reduce((sum, current) => sum + current, 0);
  }

  private createRideTypePerforms(): RiderTypePerform[] {
    return this.data.route.riderTypes.map(riderType => ({
        riderTypeId: riderType.riderTypeId,
        isActive: riderType.isActive,
        riderTypeDescription: riderType.riderTypeDescription,
        initialNumberOnboard: riderType.currentNumberOnboard,
        defaultFare: riderType.defaultFare,
        initialPassesOnboard: riderType.onboardPassIds,
        scannedPasses: [],
        passlessBoardedCount: 0,
        passlessDebordedCount: 0,
        totalPasslessFareCollected: 0,
      }))
      .sort((a, b) => a.riderTypeDescription > b.riderTypeDescription ? 1 : a.riderTypeDescription < b.riderTypeDescription ? -1 : 0);
  }

  async dismiss(): Promise<void> { await this.modalController.dismiss(); }

  public totalOnboard(perform: RiderTypePerform): number {
    return totalOnboard(perform);
  }

  public totalBoardedAtThisStop(perform: RiderTypePerform): number {
    return totalBoardedAtThisStop(perform);
  }

  public totalPassFareCollected(perform: RiderTypePerform): number {
    return totalPassFareCollected(perform);
  }

  public totalDeboardedAtThisStop(perform: RiderTypePerform): number {
    return totalDeboardedAtThisStop(perform);
  }

  boardedChanged(perform: RiderTypePerform): void {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'click_ board' });
    perform.totalPasslessFareCollected = (perform.passlessBoardedCount * perform.defaultFare);
  }

  /************* Onboard functionality ************/

  // handle the onBoard Pass click event
  async onboardPassClicked(recursive: boolean, useModal: boolean): Promise<void> {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'click_ onboard_pass' });
    if (!this.deviceService.isNativeDevice || useModal) {
      const passInfoModal = await this.modalController.create({
        component: PassEntryPage,
        cssClass: 'parascope-smaller-modal',
        backdropDismiss: false,
      });

      await passInfoModal.present();
      const { data } = await passInfoModal.onDidDismiss();

      // if we returned some data then it is a passId string and we can handle pass scan
      // if there is no data then we cancelled and we are just done here
      if (data) await this.handlePickupPassScan(data, recursive, useModal);

    } else {
      try {
        const result = await this.pluginService.activateBarcodeScanner(this.translateService.translate('LABEL.placeBarcode'));
        if (result.cancelled) return;
        await this.handlePickupPassScan(result.text, recursive, useModal);
      } catch (error) {
        alert(this.translateService.translate('LABEL.scanningFailed') + ' ' + error);
      }
    }
  }

  /* once a pass is scanned handle it */
  private async handlePickupPassScan(passId: string, recursive: boolean, useModal: boolean): Promise<void> {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: this.translateService.translate('LABEL.checkingPass'),
      backdropDismiss: false,
    });

    await loading.present();
    const passInfo: PassDetails | null = await this.dispatcherService.getFlagStopPassInformation(passId).catch(err => {
      console.error(err);
      this.showAlert(this.translateService.translate('MESSAGE.getFlagStopPassInfoError'));
      return null;
    });
    await loading.dismiss();

    const riderTypePerform = this.riderTypePerforms.find(t => t.riderTypeId === passInfo.riderTypeId);

    if (FlagStopPerformPage.driverConfirmationRequired(passInfo, recursive)) {
      const passInfoModal = await this.modalController.create({
        component: FlagStopPassScanPage,
        cssClass: 'parascope-small-modal',
        backdropDismiss: false,
        componentProps: { passInfo, riderTypePerform, passId },
      });
      await passInfoModal.present();

      // wait for the modal to dismiss and then if it is recursive then scan again
      await passInfoModal.onDidDismiss();
      if (recursive) this.onboardPassClicked(recursive, useModal);

    } else {

      if (this.deviceService.isNativeDevice) (<any>navigator).notification.beep(1);

      riderTypePerform.scannedPasses.push({ passId: passId, cashFareCollected: 0, isPickup: true } as FlagStopPassScan);
      this.notificationService.info(this.buildOnboardPassAcceptedWithoutConfirmationMessage(passInfo), 5000, true);
      if (recursive) this.onboardPassClicked(recursive, useModal);
    }

  }

  private buildOnboardPassAcceptedWithoutConfirmationMessage(passInfo: PassDetails): string {
    const passText = passInfo.assignedRiderName != null && passInfo.assignedRiderName.length > 0 ?
      `${this.translateService.translate('LABEL.for')} ${passInfo.assignedRiderName} ` : '';
    if (isCashPass(passInfo.passType)) {
      const balanceStr = this.numberPipe.transform(passInfo.remainingBalance || 0, '.2-2');

      return `${this.translateService.translate('LABEL.pass')} ${passText}`
        + `${this.translateService.translate('LABEL.passAcceptedBalance')}  ${balanceStr} ${this.translateService.translate('LABEL.remaining')}.`;
    } else {
      const expDateStr = passInfo.expirationDate != null ?
        `${this.translateService.translate('LABEL.expiresOn')} ${this.datePipe.transform(passInfo.expirationDate, 'shortDate')}` :
        this.translateService.translate('LABEL.doesNotExpire');

      return `Pass ${passText}`
        + ` ${this.translateService.translate('LABEL.acceptedPassHas')} ${(passInfo.remainingRides || 0) > 0 ? `${passInfo.remainingRides - 1}` : `${this.translateService.translate('LABEL.unlimited')}`}`
        + ` ${this.translateService.translate('LABEL.ridesRemainAfter')} ${expDateStr}.`;
    }
  }


  /************* De-board functionality ************/
  // Handle the de-board pass click event

  deboardPassClicked(): void {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'click_ deboard_pass' });
    if (!this.deviceService.isNativeDevice) {
      this.alertService.presentAlert({
        header: this.translateService.translate('LABEL.enterPassId'),
        inputs: [{ type: 'text', name: 'passId' }],
        buttons: [
          this.translateService.translate('ACTION.cancel'),
          {
            text: this.translateService.translate('ACTION.ok').toUpperCase(),
            cssClass: 'primary',
            handler: data => this.handleDeboardPassScan(data.passId),
          },
        ],
      });
    } else {
      this.pluginService.activateBarcodeScanner(this.translateService.translate('LABEL.placeBarcode'))
        .then(async (result) => {
          if (!result.cancelled) { this.handleDeboardPassScan(result.text); }
        }).catch((error) => {
          alert(this.translateService.translate('LABEL.scanningFailed') + ' ' + error);
        });
    }
  }

  // Once a pass has been scanned for de-boarding, lookup the type of rider so that it can be added to the correct row on the UI
  private handleDeboardPassScan(passId: string): void {
    const perform = this.riderTypePerforms.find(t =>
      t.initialPassesOnboard.find(p => p.toUpperCase() === passId.toUpperCase()) != null
      || t.scannedPasses.find(p => p.passId === passId) != null,
    );

    if (perform && this.totalOnboard(perform) === 0) {
      // we found the pass but the rider type on that pass has 0 onboard riders
      this.showAlert(`${this.translateService.translate('LABEL.noRidersTypeOf')} '
            + '${perform.riderTypeDescription}. .`);
    } else if (perform && this.totalOnboard(perform) > 0) {
      // we found the pass and it has riders onboard
      perform.scannedPasses.push({ passId: passId, cashFareCollected: 0, isPickup: false } as FlagStopPassScan);
      this.notificationService.info(`${perform.riderTypeDescription} ${this.translateService.translate('LABEL.noDeboardPass')}.`, 5000, true);
    } else {
      // we didn't find the pass onboard
      this.showAlert(this.translateService.translate('LABEL.scannedPassNotFound') + '.');
    }
  }

  async completeFlagStopClicked(): Promise<void> {
    this.gtm.push({ event: 'custom_event', custom_event_name: this.scheduledFlagStop?.id ? 'click_complete_scheduled_stop' : 'click_complete_flag_stop' });

    await this.locationService.setOdometer(this.odometer);

    const jobs: FlagStopJob[] = this.riderTypePerforms
      .filter(t => totalBoardedAtThisStop(t) > 0 || totalDeboardedAtThisStop(t) > 0)
      .reduce((acc, t) => {

      const riderType = this.data.route.riderTypes.find(s => s.riderTypeId === t.riderTypeId);

      // add a job for the passless pickups
      if (t.passlessBoardedCount > 0) {
        acc = [ ...acc, <FlagStopJob>{
          isPickup: true,
          riderTypeId: t.riderTypeId,
          units: t.passlessBoardedCount,
          cashFareCollected: t.totalPasslessFareCollected,
        }];
      }

      // add a job for the passless drop-Offs
      if (t.passlessDebordedCount > 0) {
        acc = [ ...acc, <FlagStopJob>{
          isPickup: false,
          riderTypeId: t.riderTypeId,
          units: t.passlessDebordedCount,
          cashFareCollected: 0,
        }];
      }

      // add a job for each scanned pass
      acc = [ ...acc, ...(t.scannedPasses || [])?.map(u => (<FlagStopJob>{
        isPickup: u.isPickup,
        riderTypeId: t.riderTypeId,
        units: 1, // each pass scan represents 1 unit currently
        cashFareCollected: u.cashFareCollected,
        passId: u.passId,
      }))];

      // add the passes that boarded here and remove those that deboarded
      const onBoardPasses = riderType.onboardPassIds;

      // If the number of on board passengers = the number of scanned passes
      //  then it is safe to assume the driver deboarded the other scanned passes without scanning them
      if (t.scannedPasses.length === this.totalOnboard(t)) {
        onBoardPasses.length = 0;
        const passes = t.scannedPasses.map(s => s.passId);
        onBoardPasses.push.apply(onBoardPasses, passes);
        return acc;
      }

      // If the number on board is 0 then we can assume all scanned passes were deboarded
      if (this.totalOnboard(t) === 0) {
        onBoardPasses.length = 0;
        return acc;
      }

      // at this point there are indeed passes that are still onboard.
      // adjust the list of scannedPasses based on what happened at this stop

      // add the passes that boarded
      onBoardPasses.push.apply(onBoardPasses, t.scannedPasses.filter(s => s.isPickup).map(s => s.passId));

      // remove the passes that deboarded
      t.scannedPasses.filter(s => !s.isPickup).map(s => s.passId).forEach(s => {
        const index = onBoardPasses.indexOf(s.toUpperCase());
        if (index !== -1) onBoardPasses.splice(index, 1);
      });

      return acc;

    }, []);

    // let the dispatcher service know about the flagStopPerform
    this.data.set('route', { ...this.data.route, routeTypes: this.data.route.riderTypes });
    await this.dispatcherService.performFlagStop(this.scheduledFlagStop, jobs);

    //  mark it as complete in the UI
    this.scheduledFlagStop.performed = true;

    // return the user to the job list
    await this.modalController.dismiss();
  }
  private showAlert(message: string): void {
    // The server is not available for the pass information
    this.alertService.presentAlert({
      message: message,
      buttons: [
        {
          text: this.translateService.translate('ACTION.ok').toUpperCase(),
          cssClass: 'primary',
        },
      ],
    });
  }
}
