import packageJSON from '../../package.json';
const version = packageJSON.version;
const currentMajorVersion = Number(version.split('.')[0]);

export const environment = {
  production: true,
  onTheWayQueryString: '?code=Q3ENEQY7vrqrnFQ/1EbaqJasH2BdsEnMADeewmobpI76GzTN4bLH0w==',
  updateLocationQueryString: '?code=rSbPTVX93a7MpKau1WoFpiVsOO67ZJpBgsVafQYReFf5mR6gPxaJiw==',
  appName: 'ParaScope',
  companyName: 'CTS Software',
  defaultDispatcherUrl: 'https://parascope.tripmastersoftware.com/api/tabletDispatcher/',
  dispatcherEndpoints: [
    { url: 'https://parascope.tripmastersoftware.com/api/tabletDispatcher/', name: 'Production' },
    { url: 'https://parascope-qa.tripmastersoftware.com/api/tabletDispatcher/', name: 'Testing' },
    { url: 'https://mdt-api-dev.ecolane.com/api/tabletDispatcher/', name: 'EcoDev' },
    { url: 'https://parascope-dev.tripmastersoftware.com/api/tabletDispatcher/', name: 'Development' },
    { url: 'https://bcc13b8c-b528-4319-9aee-1a8bd928d6ac.mock.pstmn.io/tabletDispatcher/', name: 'Mock' },
    { url: 'https://mock-endpoint/api/tabletDispatcher/', name: 'Demo', isMockEnvironment: true },
    { url: 'https://localhost:44334', name: 'Local 44334' },
    { url: 'https://localhost:50967', name: 'Local 50967' },
  ],
  defaultChannel: 'ParaScope_Capacitor.Production',
  channels: [
    { channel: currentMajorVersion > 7 ? `ParaScope_Capacitor${currentMajorVersion}.Production` : 'ParaScope_Capacitor.Production', name: 'Production' },
    { channel: currentMajorVersion > 7 ? `ParaScope_Capacitor${currentMajorVersion}.Dev`        : 'ParaScope_Capacitor.Dev', name: 'Development' },
    { channel: currentMajorVersion > 7 ? `ParaScope_Capacitor${currentMajorVersion}.Testing`    : 'ParaScope_Capacitor.Testing', name: 'Testing' },
    { channel: currentMajorVersion > 7 ? `ParaScope_Capacitor${currentMajorVersion}.Production` : 'ParaScope_Capacitor.Production', name: 'Mock' },
    { channel: currentMajorVersion > 7 ? `ParaScope_Capacitor${currentMajorVersion}.Production` : 'ParaScope_Capacitor.Production', name: 'Demo' },
    { channel: currentMajorVersion > 7 ? `ParaScope_Capacitor${currentMajorVersion}.Dev`        : 'ParaScope_Capacitor.Dev', name: 'Local 44334' },
    { channel: currentMajorVersion > 7 ? `ParaScope_Capacitor${currentMajorVersion}.Dev`        : 'ParaScope_Capacitor.Dev', name: 'Local 50967' },
  ],
  trackJS: {
    token: '85bb494f73664aa19e6b1b6237d458a5',
    application: 'parascopev7',
  },
  developerEmail: 'developers@cts-software.com',
  features: {
    calculateJobFare: false,
    combineJob: false,
    darkMode: true,
    defaultEnvToProd: false,
    defaultTheme: null,
    distanceTolerance: .5, // in miles
    driverNumberInputType: 'number',
    editPassengerCount: true,
    enableGoogleMapsApiCall: false,
    enforceFlagStopSequence: false,
    enforceScheduledBreakTimes: false,
    groupStopPerform: false,
    hasPullIn: false,
    hasPullOut: false,
    notifyAdditionalJobs: true,
    notifyPassenger: true,
    onlyAtNextLocationEnabled: false,
    onTheWayButton: true,
    overrideChannelName: true,
    requireEmptyListForPostTrip: false,
    requireVehicleNumber: false,
    sendLocationUpdates: true,
    showClientId: false,
    showUndoPrompt: true,
    showUnits: true,
    timeTolerance: 10, //  in minutes
    translateNoShowOptions: true,
    trimName: false,
  },
  firebaseConfig:{
    apiKey: "AIzaSyDDmozHl4stdsQhPEN8Zdj9y3PHaFsos-Q",
    authDomain: "parascope-7a6d4.firebaseapp.com",
    projectId: "parascope-7a6d4",
    storageBucket: "parascope-7a6d4.appspot.com",
    messagingSenderId: "456103059974",
    appId: "1:456103059974:web:249185c544d7a1b47b9987",
    measurementId: "G-PZZ9H0E775",
  },
  translationKeys: {
    riderId: 'LABEL.riderId',
    scheduledTime: 'LABEL.scheduledTime',
    priority: 'LABEL.priority',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';

// Included with Angular CLI.
