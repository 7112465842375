import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { IScheduledEvent, Job } from '../../models/job';
import { NonServicePeriod, NonServicePeriodTypeEnum } from '../../models/non-service-period';
import { NonServiceAction, NonServiceActionTypeEnum } from '../../models/non-service-action';
import { EMPTY, Subject, catchError, firstValueFrom, interval, switchMap, takeUntil, tap } from 'rxjs';

import { ScheduledFlagStop } from '../../models/flag-stop';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';
import { LocationUtilityService } from 'src/app/services/location-utility.service';
import { AppData } from 'src/app/data';
import { Route } from 'src/app/models/route';
import { TranslateService } from 'src/app/services/translate.service';
import { DeviceService } from 'src/app/services/device.service';
import { AlertService } from 'src/app/services/alert.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AlertOptions } from '@ionic/core';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
})
export class JobListComponent implements OnInit, OnDestroy {

  private readonly alertService = inject(AlertService);
  private readonly data = inject(AppData);
  private readonly destroyed$ = new Subject<boolean>();
  private readonly deviceService = inject(DeviceService);
  private readonly notificationService = inject(NotificationService);
  private readonly translateService = inject(TranslateService);

  public scheduledEvents: ScheduledEvent[] = [];

  ngOnInit() {
    this.data.route$.pipe(
      tap(route => this.onChanges(route)),
      switchMap(() => interval(20_000).pipe()),
      tap(() => this.scheduledEvents = this.updateTimeBasedPropertiesInAllItems(this.scheduledEvents)),
      takeUntil(this.destroyed$),
      catchError(err => { console.error(err); return EMPTY; }),
    ).subscribe();

    this.data.routeNotifications$.pipe(
      takeUntil(this.destroyed$),
      catchError(err =>  { console.error(err); return EMPTY; }),
    ).subscribe(async notifications => {
      if (!notifications?.length) { return; }

      const baseAlertOptions: AlertOptions = {
        header: this.translateService.translate('TITLE.tripModification'),
        subHeader: null,
        buttons: [ { text: this.translateService.translate('ACTION.ok'), cssClass: 'primary' }],
        inputs: [],
        backdropDismiss: false,
        mode: 'md',
      };

      //  for instead of forEach because forEach does not wait
      for (const message of notifications) {
        this.playBeep();
        await new Promise(resolve => {
          this.alertService.presentAlert(
            { ...baseAlertOptions, message },
            () => { resolve(true); },
          );
        });
      }

      //  reset the array because the user has been notified
      this.data.set('routeNotifications', []);
    });

    this.data.priorityMessages$.pipe(
      takeUntil(this.destroyed$),
      catchError(err =>  { console.error(err); return EMPTY; }),
    ).subscribe(async priorityMessages => {
      if (!priorityMessages?.length) { return; }
      for (const message of priorityMessages) {
        this.playBeep();
        const notification = this.notificationService.priorityMessage(message.message);
        await firstValueFrom(notification.onHidden);
      }
      //  reset the array because the user has been notified
      this.data.set('priorityMessages', []);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onChanges(route: Route) {
    const { jobs } = route;
    let stagingArray: ScheduledEvent[] = [
      ...<ScheduledEvent[]>(jobs || []).map(j => ({ ...j, vmType: 'job', scheduledDateTime: j.scheduledTime ? DateTime.fromISO(j.scheduledTime as any) : '00:00', id: `${j.rideId}-${j.jobType}` })),
      ...<ScheduledEvent[]>this.data.scheduledFlagStops.map(j => ({ ...j, vmType: 'scheduledFlagStop' })),
    ];

    if (route.break && !route.break.hasBeenCompleted) { stagingArray = [ ...stagingArray, { ...route.break, vmType: 'nonServicePeriod', id: 'break' } ]; }
    if (route.preTrip && !route.preTrip.hasBeenCompleted) { stagingArray = [ ...stagingArray, { ...route.preTrip, vmType: 'nonServicePeriod', id: 'preTrip', isEnabled: true } ]; }

    // pull out
    if (environment.features.hasPullOut && !this.data.currentRouteIsPreview && !route.pullOutPerformed && ((route.preTrip?.hasBeenCompleted ?? false) || !route.preTrip)) {
      stagingArray = [
        ...stagingArray,
        {
          vmType: 'nonServiceAction',
          nonServiceActionType: NonServiceActionTypeEnum.pullOut,
          scheduledStart: route.scheduledStart,
          id: 'pullOut'
        }
      ];
    }

    // only add pull in if all jobs or flag stops are completed
    const hasIncompleteStops = route.scheduledFlagStops?.some(f => !f.performed);
    if (environment.features.hasPullIn && !this.data.currentRouteIsPreview
      && route.pullOutPerformed && !route.pullInPerformed
      && !route.jobs?.length && !hasIncompleteStops
    ) {
      stagingArray = [
        ...stagingArray,
        {
          vmType: 'nonServiceAction',
          nonServiceActionType: NonServiceActionTypeEnum.pullIn,
          scheduledStart: route.scheduledEnd,
          isLastItem: !route.postTrip && !route.jobs?.length,
          id: 'pullIn'
        }
      ];
    }

    // conditionally add post trip when:
    if ((this.data.currentRouteIsPreview // 1. preview route
      || !environment.features.hasPullIn // 2. no pull in feature
      || (environment.features.hasPullIn && route.pullInPerformed)) // 3. pull in has been performed
      && route.postTrip && !route.postTrip.hasBeenCompleted) {
        stagingArray = [ ...stagingArray, { ...route.postTrip, isLastItem: !route.jobs?.length, vmType: 'nonServicePeriod', id: 'postTrip' } ];
    }

    stagingArray = this.updateTimeBasedPropertiesInAllItems(stagingArray);

    // Now sort them
    stagingArray.sort((a, b) => {
      const startOfTime = DateTime.fromMillis(0);
      const aTime = this.getTime(a) || startOfTime;
      const bTime = this.getTime(b) || startOfTime;

      if (a.vmType === 'nonServicePeriod' && (<NonServicePeriod>a).nonServicePeriodType === NonServicePeriodTypeEnum.PreTrip) { return -1; }
      if (b.vmType === 'nonServicePeriod' && (<NonServicePeriod>b).nonServicePeriodType === NonServicePeriodTypeEnum.PreTrip) { return 1; }
      if (a.vmType === 'nonServicePeriod' && (<NonServicePeriod>a).nonServicePeriodType === NonServicePeriodTypeEnum.PostTrip) { return 1; }
      if (b.vmType === 'nonServicePeriod' && (<NonServicePeriod>b).nonServicePeriodType === NonServicePeriodTypeEnum.PostTrip) { return -1; }
      if (aTime === startOfTime) { return 1; }
      if (bTime === startOfTime) { return -1; }
      if (aTime > bTime) return 1;
      if (aTime < bTime) return -1;
      return 0;
    });

    let previousFlagStop: ScheduledEvent<ScheduledFlagStop> = null;
    let previousJob: ScheduledEvent<Job> = null;

    stagingArray = stagingArray.map((item, i) => {
      // enforce flagStop sequence
      if (environment.features.enforceFlagStopSequence && item.vmType === 'scheduledFlagStop') {
        const flagStop = item as ScheduledEvent<ScheduledFlagStop>;
        flagStop.isAtNextLocation = !previousFlagStop || previousFlagStop?.performed;
        previousFlagStop = flagStop;
        return flagStop;
      }

      if (item.vmType !== 'job') {
        return item;
      }

      const job = item as ScheduledEvent<Job>;
      const currentCpsLocation = job?.location?.coordinate;
      job.isAtNextLocation =
        !previousJob
        || !environment.features.onlyAtNextLocationEnabled
        || (!!previousJob.onTheWayTime && environment.features.onTheWayButton)
        || (
            (
              previousJob.location.coordinate?.latitude === currentCpsLocation?.latitude
              && previousJob.location.coordinate?.longitude === currentCpsLocation?.longitude
            )
             && previousJob.isAtNextLocation
           );
      previousJob = job;
      return job as ScheduledEvent<any>;
    });

    //  Grouped stops based on time and distance tolerance if feature is enabled
    if (environment.features.groupStopPerform) {
      const { distanceTolerance, timeTolerance } = environment.features;
      const jobs = stagingArray.filter(i => i.vmType === 'job') as any;
      stagingArray = stagingArray.reduce((acc, item) => {
        if (item.vmType !== 'job') { return [ ...acc, item ]; }
        const job = item as ScheduledEvent<Job>;
        //  not grouping if no scheduled time
        if (!job.scheduledTime) { return [ ...acc, item ];}

        //  figure out if the job needs to be part of a group
        const needsGrouped = jobs.filter(j => j.rideId !== job.rideId).some(j => {
          const distance = LocationUtilityService.euclideanDistanceInMiles(
            j.location.coordinate?.latitude,
            j.location.coordinate?.longitude,
            job.location.coordinate?.latitude,
            job.location.coordinate?.longitude,
          );
          const timeDiff = Math.abs(j.scheduledDateTime.valueOf() - job.scheduledDateTime.valueOf());
          return distance <= distanceTolerance && timeDiff <= timeTolerance * 60000;
        });
        if (!needsGrouped) { return [ ...acc, item ]; }

        //  Find the group the job needs to be placed in
        const group = acc.find(group => {
          if (group.vmType !== 'group') { return false; }
          const distance = LocationUtilityService.euclideanDistanceInMiles(
            group.jobs?.[0].location.coordinate?.latitude,
            group.jobs?.[0].location.coordinate?.longitude,
            job.location.coordinate?.latitude,
            job.location.coordinate?.longitude,
            );
          const timeDiff = Math.abs(group.time.valueOf() - job.scheduledDateTime.valueOf());
            return distance <= distanceTolerance && timeDiff <= timeTolerance * 60000;
        });
        //  Add job to an existing group
        if (group) {
          group.jobs = [ ...group.jobs, job ].sort((a, b) => {
            if (a.scheduledDateTime < b.scheduledDateTime) { return -1; }
            if (a.scheduledDateTime > b.scheduledDateTime) { return 1; }
            return 0;
          });
          group.count = group.jobs.length;
          group.time = group.jobs[0].scheduledDateTime;
          return [ ...acc ];
        }
        //  No appropriate group found, therefore create the group
        return [ ...acc, { vmType: 'group', count: 1, jobs: [ job ], time: job.scheduledDateTime }];
      }, []);
    }
    //  end of grouped stops
    this.scheduledEvents = stagingArray;
  }

  private getTime(item: ScheduledEvent): DateTime | null {
    let time;
    switch(item.vmType) {
      case 'job': time = (<Job>item).scheduledTime; break;
      case 'scheduledFlagStop': time = (<ScheduledFlagStop>item).arrivalTime; break;
      case 'nonServicePeriod': time = (<NonServicePeriod>item).scheduledStart; break;
      case 'nonServiceAction': time = (<NonServiceAction>item).scheduledStart; break;
      default: time = null;
    }
    if (item.vmType === 'group') { return (<GroupedStop>item).time; }
    if (!time) { return null; }
    const dateTime = DateTime.fromISO(time);
    return dateTime.isValid ? dateTime : null;
  }

  private updateTimeBasedPropertiesInAllItems(items: ScheduledEvent[]): ScheduledEvent[] {
    return items?.map(item => {
      const time = this.getTime(item);
      return this.updateTimeBasedProperties(item, time);
    });
  }

  private updateTimeBasedProperties(stop: ScheduledEvent, time: DateTime): ScheduledEvent {
    const now = DateTime.now();
    const eventTime = time ? time : now;
    const inThePast = eventTime < now;
    return { ...stop, inThePast, timeToStop: eventTime.toRelative() } as ScheduledEvent;
  }

  trackByRideId(index: number, job: ScheduledEvent<any>) {
    return job.id;
  }

  playBeep() {
    if (this.deviceService.isNativeDevice) (<any>navigator).notification.beep(1);
    else {
      const beep = new Audio('/assets/sounds/beep.wav');
      beep.play();
    }
  }
}

export type ScheduledEvent<T = Job | ScheduledFlagStop | NonServicePeriod | NonServiceAction | GroupedStop> = T & IScheduledEvent & {
  id?: string | number,
  isAtNextLocation?: boolean,
  vmType?: string,
  scheduledDateTime?: DateTime
};

export type GroupedStop = {
  time?: DateTime,
  count?: number,
  jobs?: ScheduledEvent<Job>[],
}
