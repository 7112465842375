import { AfterContentInit, Component, EventEmitter, forwardRef, inject, Input, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Keyboard } from '@capacitor/keyboard';
import { TranslateService } from '../../services/translate.service';
import { AlertService } from '../../services/alert.service';
import { DeviceService } from '../../services/device.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: '[incrementControl]',
  templateUrl: `./increment-control.component.html`,
  styleUrls: [`./increment-control.component.scss`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncrementalControlComponent),
      multi: true,
    },
  ],
})
export class IncrementalControlComponent implements ControlValueAccessor, AfterContentInit {

  private readonly alertService = inject(AlertService);
  private readonly deviceService = inject(DeviceService);
  private readonly translateService = inject(TranslateService);

  @Input() incName: string;
  @Input() model: number;
  @Input() showOutline: boolean;
  @Input() maxValue: number;
  @Input() minValue: number;
  @Input() integerOnly: boolean;
  @Input() allowNull = false;
  @Input() disabled = false;
  @Input() allowUndo = false;
  @Input() undoPrompt: string;
  @Input() labelledBy: string | undefined;
  @Output() valueChange = new EventEmitter<any>();

  previousModel: number;
  form: UntypedFormGroup;

  propagateChange: (_: any) => void = (_) => { }; // tslint:disable-line

  ngAfterContentInit() {
    this.minValue = this.minValue || 0;
    this.model = this.model || this.minValue || 0;
    this.previousModel = this.model;
  }

  writeValue(value: any) {
    this.model = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  increaseClicked() {
    if (isFinite(this.maxValue) && this.maxValue < this.model + 1) {
      return;
    }
    this.model++;
    this.propagateChange(+this.model);
    this.valueChange.emit(this.model);
    this.previousModel = +this.model;
    if (this.deviceService.isNativeDevice) {
      Keyboard.hide();
    }
  }

  async decreaseClicked() {
    // alert for undo
    if (this.allowUndo && !(await this.confirmUndo())) {
      return;
    }

    if (isFinite(this.minValue) && this.minValue > this.model - 1) {
      return;
    }
    this.model--;
    this.propagateChange(+this.model);
    this.valueChange.emit(this.model);
    this.previousModel = +this.model;
    if (this.deviceService.isNativeDevice) {
      Keyboard.hide();
    }
  }

  modelChanged() {
    let changeTo: any = this.model;
    if (!changeTo) {
      // Do nothing
    } else {
      const isValid = !isNaN(parseFloat(changeTo)) && isFinite(changeTo);
      if (!isValid) {
        changeTo = this.previousModel;
      }
      if (this.model < 0) {
        changeTo = this.previousModel;
      }
      if (this.maxValue && this.maxValue < +changeTo) {
        changeTo = this.previousModel;
      }
      if (this.minValue && this.minValue > +changeTo) {
        changeTo = this.minValue;
      }

      // Discard decimal if integerOnly = true. eg. for number of children.
      if (this.integerOnly) {
        changeTo = Math.floor(changeTo);
      }

      // Check for more than two decimals
      let changeToString = '' + (changeTo || 0);
      const indexOfDot = changeToString.indexOf('.');
      if (indexOfDot > -1) {
        const lengthOfStringAfterDot = changeToString.substring(indexOfDot).length;
        if (lengthOfStringAfterDot > 3) {
          changeToString = changeToString.substring(0, indexOfDot + 3);
          changeTo = +changeToString;
        }
      }
    }

    setTimeout(() => {
      this.model = changeTo;
      this.propagateChange(+this.model);
      this.valueChange.emit(this.model);
      this.previousModel = +this.model;
    }, 1);
  }

  onEventInputChange($event) {
    const val = $event.target.value;
    if (this.maxValue && +val > this.maxValue) {
      $event.target.value = this.maxValue;
    }
    if (this.minValue && +val < this.minValue) {
      $event.target.value = this.minValue;
    }
  }

  onBlur() {
    if (!this.allowNull) {
      this.model = parseFloat(<any>this.model);
      if (isNaN(this.model)) {
        this.model = this.minValue || 0;
      }
    }
    this.previousModel = this.model;
  }

  public async confirmUndo(): Promise<boolean> {
    // skip alert if feature is disabled
    if (!environment.features.showUndoPrompt) { return true; }

    return new Promise<boolean>(async (resolve) => {
      await this.alertService.presentAlertAsync({
        message: this.undoPrompt ?? this.translateService.translate('MESSAGE.undo'),
        buttons: [
          {
            text: this.translateService.translate('LABEL.continue'),
            cssClass: 'primary',
            handler: async () => resolve(true),
          },
          {
            text: this.translateService.translate('ACTION.cancel'),
            cssClass: 'danger',
            handler: () => resolve(false),
          },
        ],
      });
    });
  }
}
