<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="totalPassengersBoardedOrDeboardedAtThisStop <= 0 || scheduledFlagStop">
      <ion-button (click)="dismiss()" size="default" color="secondary" fill="outline">
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="scheduledFlagStop?.id">
      <small>{{ 'LABEL.performScheduledStop' | translate }}</small>
      <br>{{ scheduledFlagStop.location }} <ng-container *ngIf="scheduledFlagStop.direction">({{ scheduledFlagStop.direction }})</ng-container>
    </ion-title>
    <ion-title *ngIf="!scheduledFlagStop?.id">{{ 'LABEL.performFlagStop' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="margin">
    <div class="odometer">
      <span class="label">{{ 'LABEL.odometer' | translate }}</span>
      <div
        class="incrementer"
        incrementControl
        [maxValue]="999999"
        [incName]="'LABEL.odometer' | translate"
        [(ngModel)]="odometer"
        [integerOnly]="true"
      >
      </div>
    </div>
  </div>

  <ion-grid class="on-board-pass" *ngIf="paraPassEnabled">
    <ion-row>
      <ion-col size-xs="12" size-md="6">
        <ion-button
          color="secondary"
          (click)="onboardPassClicked(false, false)">
          {{ 'LABEL.onboardPass' | translate }}
        </ion-button>
      </ion-col>
      <ion-col size-xs="12" size-md="6">
        <ion-button
          color="secondary"
          fill="outline"
          (click)="deboardPassClicked()"
          [disabled]="totalPassengersOnBoard == 0">
          {{ 'LABEL.deboardPass' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" size-md="6">
        <ion-button
          color="tertiary"
          (click)="onboardPassClicked(true, false)">
            {{ 'LABEL.rapidOnboardTablet' | translate }}
        </ion-button>
      </ion-col>
      <ion-col size-xs="12" size-md="6">
        <ion-button
          color="tertiary"
          fill="outline"
          (click)="onboardPassClicked(true, true)">
            {{ 'LABEL.rapidOnboardBluetooth' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="margin">
    <h2>{{ 'LABEL.passlessBoardingDeboarding' | translate }}</h2>
  </div>

  <ng-container *ngFor="let item of riderTypePerforms">
    <ion-card
      *ngIf="
        (totalBoardedAtThisStop(item) || 0) > 0
        || (totalDeboardedAtThisStop(item) || 0) > 0
        || (totalOnboard(item) || 0) > 0
        || item.isActive
      "
    >
      <ion-card-header>
        <ion-grid class="ion-no-margin ion-no-padding">
          <ion-row>
            <ion-col size="4">
              <ion-card-title>{{ item.riderTypeDescription }}</ion-card-title>
            </ion-col>
            <ion-col size="8">
              <ion-card-title class="label">{{ 'LABEL.currentlyOnboard' | translate }}: {{ totalOnboard(item) }}</ion-card-title>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-header>
      <ion-card-content>
        <ion-grid class="ion-no-margin ion-no-padding">
          <ion-row>
            <ion-col size-xs="12" size-md="4">
              <!-- boarded -->
              <ion-grid>
                <ion-row>
                  <ion-col size-xs="6" size-md="12">
                    <ion-label>{{ 'LABEL.boardedHere' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size-xs="6" size-md="12">
                    <!-- incrementer -->
                    <div
                      class="incrementer"
                      incrementControl
                      [showOutline]="true"
                      [allowUndo]="true"
                      [undoPrompt]="'MESSAGE.undoBoard' | translate"
                      (valueChange)="boardedChanged(item)"
                      [(ngModel)]="item.passlessBoardedCount">
                    </div>
                    <!-- /incrementer -->
                  </ion-col>
                </ion-row>
              </ion-grid>
              <!-- /boarded -->
            </ion-col>
            <ion-col size-xs="12" size-md="4">
              <!-- deboarded -->
               <ion-grid>
                <ion-row>
                  <ion-col size-xs="6" size-md="12">
                    <ion-label>{{ 'LABEL.deBoardedHere' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size-xs="6" size-md="12">
                    <div
                      class="incrementer"
                      incrementControl
                      [showOutline]="true"
                      [allowUndo]="true"
                      [undoPrompt]="'MESSAGE.undoDeboard' | translate"
                      [maxValue]="item.initialBoardedCount"
                      [(ngModel)]="item.passlessDebordedCount">
                    </div>
                  </ion-col>
                </ion-row>
               </ion-grid>
              <!-- /deboarded -->
            </ion-col>
            <ion-col size-xs="12" size-md="4">
              <!-- fare collected -->
              <ion-grid>
                <ion-row>
                  <ion-col size-xs="6" size-md="12">
                    <ion-label>{{ 'LABEL.fareCollected' | translate }}</ion-label>
                  </ion-col>
                  <ion-col size-xs="6" size-md="12">
                    <div
                      class="incrementer"
                      incrementControl
                      [showOutline]="true"
                      [(ngModel)]="item.totalPasslessFareCollected">
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <!-- /fare collected -->
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ng-container>

</ion-content>

<ion-footer>

  <ion-grid class="summary">
    <ion-row>
      <ion-col size="4" class="ion-no-padding ion-no-margin">
        <ion-card>
          <ion-card-content>
            <div class="ion-text-center">
              <ion-label>{{ totalPassengersBoarded }}</ion-label>
              <ion-label>{{ 'LABEL.boardedHere' | translate }}</ion-label>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col size="4" class="ion-no-padding ion-no-margin">
        <ion-card>
          <ion-card-content>
            <div class="ion-text-center">
              <ion-label>{{ totalPassengersDeboarded }}</ion-label>
              <ion-label>{{ 'LABEL.deBoardedHere' | translate }}</ion-label>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col size="4" class="ion-no-padding ion-no-margin">
        <ion-card>
          <ion-card-content>
            <div class="ion-text-center">
              <ion-label>{{ totalPassengersOnBoard }}</ion-label>
              <ion-label>{{ 'LABEL.currentlyOnboard' | translate }}</ion-label>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-button
    color="primary"
    expand="block"
    (click)="completeFlagStopClicked()"
    [disabled]="false">
    {{ 'LABEL.completeStop' | translate }}
    <ion-icon name="checkmark" slot="end"></ion-icon>
  </ion-button>

</ion-footer>
