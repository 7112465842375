<div [class.showOutline]="showOutline" class="increment">
  <ion-item fill="outline" mode="md">
    <ion-input
      [attr.aria-labelledby]="labelledBy"
      [attr.label]="!showOutline && incName ? incName : ''"
      [attr.aria-label]="labelledBy ? '' : incName || 'number'"
      type="number"
      [max]="maxValue"
      class="increment-control-input"
      [(ngModel)]="model"
      (ngModelChange)="modelChanged()"
      (input)="onEventInputChange($event)"
      (blur)="onBlur()"
      [disabled]="disabled"
    >
    </ion-input>
  </ion-item>

  <ion-button
    fill="outline"
    (click)="decreaseClicked()"
    [disabled]="disabled"
    [attr.aria-label]="'ACTION.increment' | translate"
    [attr.aria-describedby]="labelledBy"
    >
    <ion-icon *ngIf="!allowUndo" name="remove" slot="icon-only"></ion-icon>
    <ion-icon *ngIf="allowUndo" name="arrow-undo-outline" slot="icon-only"></ion-icon>
  </ion-button>

  <ion-button
    fill="outline"
    (click)="increaseClicked()"
    [disabled]="disabled"
    [attr.aria-label]="'ACTION.decrement' | translate"
    [attr.aria-describedby]="labelledBy"
  >
    <ion-icon name="add" slot="icon-only"></ion-icon>
  </ion-button>
</div>
