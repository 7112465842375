import { Component, Input, OnInit, OnDestroy, inject } from '@angular/core';
import { NonServicePeriod, NonServicePeriodTypeEnum } from '../../models/non-service-period';

import { AlertService } from '../../services/alert.service';
import { AppData } from 'src/app/data';
import { DeviceService } from '../../services/device.service';
import { DispatcherService } from '../../services/dispatcher.service';
import { InspectionItem } from '../../models/inspection';
import { InspectionPage } from '../../pages/inspection/inspection.page';
import { ModalController } from '@ionic/angular';
import { RouteProviderService } from '../../services/route-provider.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '../../services/translate.service';
import { DateTime } from 'luxon';
import { catchError, EMPTY, interval, Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-non-service-period',
  templateUrl: './non-service-period.component.html',
  styleUrls: ['./non-service-period.component.scss'],
})
export class NonServicePeriodComponent implements OnInit, OnDestroy {

  public readonly data = inject(AppData);
  public restrictStartStop = false;

  private readonly destroyed$ = new Subject<boolean>();

  @Input() nonServicePeriod: NonServicePeriod;
  typeString = '';

  constructor(public alertService: AlertService,
    public router: Router,
    private routeProvider: RouteProviderService,
    public deviceService: DeviceService,
    private modalController: ModalController,
    public dispatcherService: DispatcherService,
    private translateService: TranslateService,
    private title: Title,
  ) {
  }

  ngOnInit() {
    switch (this.nonServicePeriod.nonServicePeriodType) {
      case NonServicePeriodTypeEnum.PreTrip:
        this.typeString = this.translateService.translate('LABEL.preTrip');
        break;
      case NonServicePeriodTypeEnum.Break:
        this.typeString = this.translateService.translate('LABEL.break');
        break;
      case NonServicePeriodTypeEnum.PostTrip:
        this.typeString = this.translateService.translate('LABEL.postTrip');
        break;
    }

    if (environment.features.enforceScheduledBreakTimes && this.nonServicePeriod.isEnabled &&
      !this.data.currentRouteIsPreview && this.nonServicePeriod.nonServicePeriodType === NonServicePeriodTypeEnum.Break) {
      // Start timer for break to determine if the break action button should be shown
      const timer$ = interval(1000);
      const currentTime = DateTime.now();

      const startTime = DateTime.fromJSDate(new Date(this.nonServicePeriod.scheduledStart));
      const endTime = DateTime.fromJSDate(new Date(this.nonServicePeriod.scheduledEnd));

      const earliestStartTime = startTime.minus({seconds: this.data.route.breakStartEarlinessLimit ?? 0});
      const earliestEndTime = endTime.minus({seconds: this.data.route.breakEndEarlinessLimit ?? 0});

      // enforce by default when feature is enabled
      this.restrictStartStop = environment.features.enforceScheduledBreakTimes;

      timer$.pipe(
        takeUntil(this.destroyed$),
        catchError(err =>  {
          console.error(err);
          return EMPTY;
        }),
      ).subscribe(() => {
        // account for pull out
        if (environment.features.hasPullOut && !this.data.route.pullOutPerformed) {
          this.restrictStartStop = true;
          return;
        }

        // account for stop sequence
        if (environment.features.enforceFlagStopSequence) {
          // check of prior flagStops (if any) are completed
          const incompletePriorFlagStops = this.data.route.scheduledFlagStops?.filter(flagStop => !flagStop.performed
            && flagStop.arrivalTime < this.nonServicePeriod.scheduledStart) ?? [];
          if (incompletePriorFlagStops.length > 0) {
            return;
          }
        }

        const canStartBreak = !this.nonServicePeriod.hasBeenStarted && earliestStartTime <= currentTime;
        const canEndBreak = !this.nonServicePeriod.hasBeenCompleted && this.nonServicePeriod.hasBeenStarted && earliestEndTime <= currentTime;
        this.restrictStartStop = !(canStartBreak || canEndBreak);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  async startClicked() {
    // Check if this is a Post-Trip and it has inspections to be done - handled differently than Pre-Trip, since
    // that's automatically started
    const now = new Date();

    const alertFailedInspectionOptions = {
      cssClass: 'alert-wrapperz',
      header: this.translateService.translate('TITLE.inspFailed'),
      message: this.translateService.translate('LABEL.criticalInspFailed'),
      buttons: [{
        text: this.translateService.translate('ACTION.ok').toUpperCase(),
        cssClass: 'primary',
      }],
    };

    const onInspectionDismiss = (success: boolean, inspectionItems: InspectionItem[]) => new Promise(async resolve => {
      this.nonServicePeriod.hasBeenStarted = true;

      await this.routeProvider.startNonServicePeriod(this.nonServicePeriod.nonServicePeriodType, now);
      await this.routeProvider.finishNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
      await this.dispatcherService.addInspectionReport(inspectionItems, this.nonServicePeriod.nonServicePeriodType);

      if (success) { await this.modalController.dismiss(); resolve(true); return; }

      // inspection failed
      await this.alertService.presentAlert(alertFailedInspectionOptions);
      await this.dispatcherService.logout();
      const _title = this.translateService.translate('ACTION.login');
      this.title.setTitle(_title);
      await this.router.navigate(['/login']);
      resolve(false);
    });

    if (
      //  is a post trip and requires inspection
      this.nonServicePeriod.nonServicePeriodType === NonServicePeriodTypeEnum.PostTrip
    ) {
      if (this.data.route.postTrip.inspectionItems?.length) {
        const inspectionModal = await this.modalController.create({
          cssClass: 'parascope-full-modal',
          backdropDismiss: false,
          component: InspectionPage,
          componentProps: {
            nonServicePeriod: this.nonServicePeriod,
            onComplete: async (success: boolean, inspectionItems: InspectionItem[]) => await onInspectionDismiss(success, inspectionItems),
          },
        });
        await inspectionModal.present();
      } else if (this.nonServicePeriod.hasBeenStarted) {
        await this.routeProvider.finishNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
      } else {
        await this.routeProvider.startNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
      }
    } else if (this.nonServicePeriod.nonServicePeriodType === NonServicePeriodTypeEnum.Break) {
      //  is a break
      this.nonServicePeriod.hasBeenStarted = true;
      await this.routeProvider.startNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
    }
  };

  async finishClicked() {
    this.nonServicePeriod.hasBeenCompleted = true;
    await this.routeProvider.finishNonServicePeriod(this.nonServicePeriod.nonServicePeriodType);
  }
}
